<template>
  <div>
    <hero></hero>
    <user-manual></user-manual> 
    <l-footer></l-footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>